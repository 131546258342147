<script>
	export default {
		globalData: {
			appname: '', // app类型 bazi|lp|calendar|ziwei|wylq
			platform: '', // 区分h5还是客户端 ''|app
			isIOS: false, // 是否为ios系统
			phone: '',
            // userId: '',
            isNative : false
		},
		onLaunch: function() {
			const queryData = this.getQuery()
            console.log('鑫',queryData)
			this.globalData.appname = queryData.appname
			this.globalData.platform = queryData.platform
            this.globalData.phone = queryData.phone
            // this.globalData.userId = queryData.userId
            this.globalData.isNative = queryData.isNative === 'true' ? true : false

			const devuceData = uni.getDeviceInfo()
			this.globalData.isIOS = devuceData.system.toLowerCase().indexOf('ios') >= 0
      
      const userList = uni.getStorageSync(this.globalData.phone)
      if(userList === ''){
        uni.setStorageSync(this.globalData.phone, {
          list: [],
          hasMore:true,
          maxTs:'0',
          userId : ''
        });
      } 
	  
	//    反复取消选区，但排除可编辑区域
            document.addEventListener('selectionchange', function() {
                const selection = document.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    const ancestor = range.commonAncestorContainer;
                    
                    // 确保 commonAncestorContainer 是 Element 才调用 closest
                    if (ancestor.nodeType === Node.ELEMENT_NODE) {
                        const editable = ancestor.closest('input, textarea, [contenteditable=true]');
                        const noCopy = ancestor.closest('.no-copy');
                        if (!editable && noCopy) {
                            selection.removeAllRanges();
                        }
                    } else if (ancestor.nodeType === Node.TEXT_NODE) {
                        const parentElement = ancestor.parentElement;
                        const editable = parentElement.closest('input, textarea, [contenteditable=true]');
                        const noCopy = parentElement.closest('.no-copy');
                        if (!editable && noCopy) {
                            selection.removeAllRanges();
                        }
                    }
                }
            });
		},
		onHide() {
			// 保存关闭时的状态
		},
		methods: {
			getQuery() {
				const queryObject = {
					appname: '',
					platform: '',
                    phone: '',
                    // userId: '',
                    isNative: false
				}
				// #ifdef WEB
				const queryString = location.search
				const params = new URLSearchParams(queryString)
				for (const [key, value] of params.entries()) {
                    queryObject[key] = value
				}
                console.log(666, location)
				// #endif
				return queryObject
			},
			getAppname() {
				let appname = ''
				switch (this.globalData.appname) {
					case 'calendar':
						appname = '吉真万年历'
						break
					case 'ziwei':
						appname = '吉真紫微'
						break
                    case 'bazi':
                        appname = '问真八字'
                        break
					default:
						appname = '匿名'
						break
				}
				return appname
			}
		}
	}
</script>

<style>
	page {
		/* 安全距离 */
		--statusHeight: 0px;
		--bottomHeight: 0px;
		--statusHeight: env(safe-area-inset-top);
		--bottomHeight: env(safe-area-inset-bottom);
	}

	::-webkit-scrollbar {
		display: none;
		width: 0 !important;
		height: 0 !important;
		-webkit-appearance: none;
		background: transparent;
	}

	.screenMask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 999;
	}
</style>