import App from './App'
// #ifndef VUE3
import Vue from 'vue'
import './uni.promisify.adaptor'
Vue.config.productionTip = false
App.mpType = 'app'
const app = new Vue({
  ...App
})
app.$mount()
// #endif



// #ifdef VUE3
import { createSSRApp } from 'vue'
// #ifndef MP-WEIXIN
import Vconsole from 'vconsole'
const vConsole = new Vconsole()
// #endif
export function createApp() {
  const app = createSSRApp(App)
  // 挂载 vConsole 以便全局访问（可选）
  // #ifndef MP-WEIXIN
  app.config.globalProperties.$vconsole = vConsole
  // #endif
  return {
    app
  }
}
// #endif